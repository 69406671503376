import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I spent `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769/status/1231387394189824000?s=20"
      }}>{`more time than I'd care to admit`}</a>{` today trying to debug why my Github Actions workflow to automatically deploy new versions of noahgilmore.com on push to `}<inlineCode parentName="p">{`master`}</inlineCode>{` was failing. The site is built on `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/"
      }}>{`Gatsby`}</a>{`, and the pipeline runs `}<inlineCode parentName="p">{`gatsby build`}</inlineCode>{`, but I was running into the following error on every push:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`Something went wrong installing the "sharp" module`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`Module did not self-register.`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`- Remove the "node_modules/sharp" directory, run "npm install" and look for errors`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`- Consult the installation documentation at https://sharp.pixelplumbing.com/en/stable/install/`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`- Search for this error at https://github.com/lovell/sharp/issues`}</span></code></pre>
    <p>{`Given `}<a parentName="p" {...{
        "href": "https://github.com/gatsbyjs/gatsby/issues/18277"
      }}>{`how`}</a>{` `}<a parentName="p" {...{
        "href": "https://github.com/lovell/sharp/issues/1899"
      }}>{`many`}</a>{` `}<a parentName="p" {...{
        "href": "https://github.com/lovell/sharp/issues/2031"
      }}>{`different`}</a>{` `}<a parentName="p" {...{
        "href": "https://github.com/lovell/sharp/issues/952"
      }}>{`people`}</a>{` `}<a parentName="p" {...{
        "href": "https://github.com/lovell/sharp/issues/1936"
      }}>{`have`}</a>{` `}<a parentName="p" {...{
        "href": "https://github.com/lovell/sharp/issues/1983"
      }}>{`reported`}</a>{` `}<a parentName="p" {...{
        "href": "https://github.com/lovell/sharp/issues/2065"
      }}>{`symptoms`}</a>{` `}<a parentName="p" {...{
        "href": "https://github.com/lovell/sharp/issues/1819"
      }}>{`of`}</a>{` what looks like the same issue, I figured I'd shared what worked for my case.`}</p>
    <p>{`The root cause of this issue seems to be that folks are trying to run `}<inlineCode parentName="p">{`gatsby build`}</inlineCode>{` with a version of node which is different than the one they installed `}<inlineCode parentName="p">{`sharp`}</inlineCode>{` with. In my case, I used to have the workflow set up do deploy like this:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "yml"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`- `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`Build and Deploy`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`uses`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`JamesIves/github-pages-deploy-action@db4aacadf4db71778afb0c8a452382184a71d318`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`env`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`:`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`ACCESS_TOKEN`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`\${{ secrets.ACCESS_TOKEN }}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`BRANCH`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`gh-pages`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`FOLDER`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`public`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`BUILD_SCRIPT`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`npm run gatsby-build`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`CNAME`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`noahgilmore.com`}</span></span></code></pre>
    <p><a parentName="p" {...{
        "href": "https://github.com/JamesIves/github-pages-deploy-action"
      }}>{`github-pages-deploy-action`}</a>{` is a great github action that automatically pushes a given local folder to your `}<inlineCode parentName="p">{`gh-pages`}</inlineCode>{` branch to deploy via `}<a parentName="p" {...{
        "href": "https://pages.github.com/"
      }}>{`Github Pages`}</a>{`. The version I was using ran everything in a Docker container and excuted the given `}<inlineCode parentName="p">{`BUILD_SCRIPT`}</inlineCode>{` before pushing. I think this was the issue - that container most likely had a different version of node.`}</p>
    <p>{`The most recent release of this action gets rid of the `}<inlineCode parentName="p">{`BUILD_SCRIPT`}</inlineCode>{` entirely, opting to let the workflow build its own folder first, and the action `}<em parentName="p">{`just`}</em>{` takes care of pushing the build folder. The working workflow looks like this:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "yml"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`- `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`npm run gatsby-build`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`run`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`npm run gatsby-build`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`- `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`Build and Deploy`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`uses`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`JamesIves/github-pages-deploy-action@releases/v3`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`with`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`:`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`ACCESS_TOKEN`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`\${{ secrets.ACCESS_TOKEN }}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`BRANCH`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`gh-pages`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`FOLDER`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`public`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`CNAME`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`noahgilmore.com`}</span></span></code></pre>
    <p>{`Single responsibilities make everything easier for everyone 👍`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      